module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KN37DR5V0M"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"fr","name":"Platinium","short_name":"platinium","start_url":"/","background_color":"#fff","theme_color":"#00e7c8","display":"minimal-ui","icon":"src/images/blob.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f662845cee53405a71860c17c8b30888"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
